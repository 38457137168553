"use strict";

angular.module("informaApp")
    .service("GanttBlockHelper", ["d3", "PhasesBarHelper", "ChartElementsHelper", function (d3, PhasesBarHelper, ChartElementsHelper) {
        var result = {};

        result.createGanttBlock = function (params) {
            var options = {
                callbacks: {
                    onFocus: null,
                    onBlur: null,
                },
                container: null,
                source: null,
                animation: {
                    direction: "auto", // left or right or auto
                    speed: {
                        open: 800,
                        close: 850
                    },
                    type: { // d3 ease types
                        open: "elastic",
                        close: "bounce"
                    }
                },
                width: 100,
                height: 78,
                x: 0,
                y: 0,
                paddingBetweenTitles: 30,
                padding: 8,
                paddingBettweenLines: 8,
                shadow: {
                    size: 1,
                    blur: 3
                },
                leftTitle: {
                    text: null,
                    link: null,
                    getTooltip: null,
                    onClick: null
                },
                leftSubTitle: {
                    text: null,
                    link: null,
                    getTooltip: null,
                    onClick: null
                },
                rightTitle: {
                    text: null,
                    link: null
                },
                status: {
                    title: null,
                    subTitle: true,
                    success: true
                },
                defsElement: null,
                shadowFilterId: "gaussian-blur",
                hideNoDataBar: false
            };

            var iconRightPadding = 5;
            options = _.merge(options, params);

            addBlurFilter(options.defsElement);

            var largeSizeValue = 0;
            var aimationDirection = options.animation.direction;

            var element = makeBlock();

            return {
                element: element,
                x: options.x,
                y: options.y,
                maxWidth: largeSizeValue,
                remove: () => {
                    element.removeTooltips();
                    element.remove();
                }
            };

            function makeBlock() {
                var container = options.container.append("g");

                var shadowBlock = appendRectShadow(container);
                var block = appendBlock(container);
                var rect = appendRect(block.element);
                var borderRect;

                function getAnimationFunciton(speed, ease, isReverse, callback) {
                    return function () {
                        animateBlock(block.element, block.x, block.y, block.width, largeSizeValue, speed, ease, isReverse, true);
                        animationWidthRight(rect.element, isReverse ? rect.width : largeSizeValue, speed, ease);

                        if (aimationDirection === "right") {
                            animationPositionX(borderRect.element, isReverse ? borderRect.x : borderRect.x + (largeSizeValue - rect.width), borderRect.y, speed, ease);
                        }

                        animateBlock(shadowBlock.element, shadowBlock.x, shadowBlock.y, shadowBlock.width, largeSizeValue + options.shadow.size * 2, speed, ease, isReverse, false);

                        if (callback) {
                            callback()
                        }
                    };
                }

                container
                    .on("mouseenter", getAnimationFunciton(options.animation.speed.open, options.animation.type.open, false, options.callbacks.onFocus))
                    .on("mouseleave", getAnimationFunciton(options.animation.speed.close, options.animation.type.close, true, options.callbacks.onBlur));

                var titleTooltip = createTip(container, options.leftTitle.getTooltip);
                var subTitleTooltip = createTip(container, options.leftSubTitle.getTooltip);

                var title = appendText(block.element, options.padding + "px", options.padding + "px", options.leftTitle, titleTooltip, "14px", "bold", "black");

                function getTextPositionY(text) {
                    return (+text.attr("y").replace("px", "") + getHeight(text) + options.paddingBettweenLines);
                }

                var subTitle = appendText(block.element, options.padding + "px", getTextPositionY(title) + "px", options.leftSubTitle, subTitleTooltip, "12px", "300", "black");

                var rightTitle = appendStatus(block.element, (largeSizeValue - options.padding) + "px", options.padding + "px", options.status, options.status.title, "14px", "bold", "#21AC47", true);
                var rightSubTitle = appendStatus(block.element, (largeSizeValue - options.padding) + "px", getTextPositionY(rightTitle) + "px", options.status, options.status.subTitle, "12px", "300", "#21AC47", true);
                var iconStatus = appendSatusIcon(block.element, (largeSizeValue - options.padding - getWidth(rightTitle) - 5) + "px", options.padding + "px", options.status.success, "14px", "bold", "#21AC47", true);

                largeSizeValue = getMaxWidth(title, subTitle, rightTitle, rightSubTitle, iconStatus);

                if (aimationDirection === "auto") {
                    var containerWidth = options.container.node().clientWidth;
                    aimationDirection = options.x + largeSizeValue > containerWidth - 10 ? "left" : "right";
                }

                rightTitle.attr("x", (largeSizeValue - options.padding) + "px");
                rightSubTitle.attr("x", (largeSizeValue - options.padding) + "px");
                iconStatus.attr("x", (largeSizeValue - options.padding - getWidth(rightTitle) - iconRightPadding) + "px")

                new PhasesBarHelper.createPhasesProgressBar({
                    container: block.element,
                    x: options.padding,
                    y: getTextPositionY(subTitle),
                    width: largeSizeValue - 16,
                    source: options.source,
                    defsElement: options.defsElement,
                    hideNoDataBar: options.hideNoDataBar
                });

                borderRect = appendBorderRect(container);

                container.removeTooltips = () => {
                    titleTooltip && titleTooltip.destroy();
                    subTitleTooltip && subTitleTooltip.destroy();
                };

                return container;
            }

            function appendBorderRect(container) {
                var result = {
                    x: (options.x + options.shadow.size) + options.width - options.padding,
                    y: options.y + options.padding,
                    width: options.padding
                };

                result.element = container.append("rect")
                    .attr("width", result.width + "px")
                    .attr("height", (options.height - options.padding) + "px")
                    .attr("transform", getTranslate(result.x, result.y))
                    .attr("fill", "white");

                return result;
            }

            function appendRect(block) {
                var result = {
                    x: 0,
                    y: 0,
                    width: options.width
                };

                result.element = block.append("rect")
                    .attr("width", result.width + "px")
                    .attr("height", options.height + "px")
                    .attr("transform", getTranslate(result.x, result.y))
                    .attr("fill", "white")

                return result;
            }

            function appendBlock(container) {
                var result = {
                    x: options.x + options.shadow.size,
                    y: options.y + options.shadow.size,
                    width: options.width
                };

                result.element = container.append("svg")
                    .attr("class", "block")
                    .attr("width", result.width + "px")
                    .attr("height", options.height + "px")
                    .attr("x", result.x + "px")
                    .attr("y", result.y + "px")

                return result;
            }

            function appendRectShadow(container) {
                var result = {
                    x: options.x,
                    y: options.y,
                    width: options.width + options.shadow.size * 2
                };

                result.element = container.append("rect")
                    .attr("width", result.width + "px")
                    .attr("height", (options.height + options.shadow.size * 2) + "px")
                    .attr("transform", getTranslate(result.x, result.y))
                    .attr("fill", getStatusColor(options.status.success))
                    .attr("opacity", "0.3")
                    .attr("filter", "url('#" + options.shadowFilterId + "')");

                return result;
            }

            function getStatusColor(success) {
                return ChartElementsHelper.getStatusColor(success);
            }

            function getIconByStatus(success) {
                return ChartElementsHelper.getIconByStatus(success);
            }

            function appendSatusIcon(container, x, y, status, fontSize, fontWeight, color, isEndAnchor) {
                var e = container.append("text")
                    .text(getIconByStatus(status))
                    .attr('class', 'glyphicon')
                    .attr("dy", "1.08em")
                    .attr("font-size", fontSize)
                    .attr("text-anchor", isEndAnchor ? "end" : "start")
                    .attr("fill", getStatusColor(status))
                    .attr("y", y)
                    .attr("x", x);

                return e;
            }

            function appendStatus(container, x, y, status, text, fontSize, fontWeight, color, isEndAnchor) {
                var e = container.append("text")
                    .text(text)
                    .attr("dy", "1.0em")
                    .attr("font-size", fontSize)
                    .attr("font-weight", fontWeight)
                    .attr("text-anchor", isEndAnchor ? "end" : "start")
                    .attr("fill", getStatusColor(status.success))
                    .attr("y", y)
                    .attr("x", x);

                return e;
            }

            function getMaxWidth(title, subTitle, rightTitle, rightSubTitle, iconStatus) {
                var maxLeftTitle = _.max([getWidth(title), getWidth(subTitle)]);
                var maxRightTitle = _.max([getWidth(rightTitle) + getWidth(iconStatus) + iconRightPadding, getWidth(rightSubTitle)]);
                var result = maxLeftTitle + options.paddingBetweenTitles + maxRightTitle + options.padding * 2;

                return result < options.width ? options.width : result;
            }

            function getWidth(element) {
                return element.node().getBoundingClientRect().width;
            }

            function getHeight(element) {
                return element.node().getBoundingClientRect().height;
            }

            function appendText(container, x, y, itemData, tooltip, fontSize, fontWeight, color, isEndAnchor) {
                var e = container.append("text")
                    .text(itemData.text)
                    .attr("dy", "1.0em")
                    .attr("font-size", fontSize)
                    .attr("font-weight", fontWeight)
                    .attr("text-anchor", isEndAnchor ? "end" : "start")
                    .attr("fill", color)
                    .attr("y", y)
                    .attr("x", x);

                var isHovered = itemData.link || tooltip;

                e.attr("class", "link")
                    .on("mouseenter", function () {
                        e.attr("style", "cursor: pointer;" + (itemData.link ? "text-decoration: underline" : ""));

                        if (tooltip) {
                            tooltip.attr('class', 'd3-tip auto')
                            tooltip.show();
                        }
                    })
                    .on("mouseleave", function () {
                        e.attr("style", "cursor: pointer; text-decoration: none");

                        if (tooltip) {
                            tooltip.destroy();
                        }
                    })
                    .on("click", function () {
                        if (!itemData.link) {
                            return;
                        }

                        if (itemData.onClick){
                            itemData.onClick();
                        }

                        var win = window.open(itemData.link, '_blank');
                        if (win) {
                            win.focus();
                        }
                        ;
                    });

                return e;
            }

            function getTranslate(x, y) {
                return "translate(" + x + "," + y + ")";
            }

            function animateBlock(element, x, y, startWidth, endWidth, speed, ease, reverse, isCoordinates) {
                if (aimationDirection === "right") {
                    animationWidthRight(element, (reverse ? startWidth : endWidth), speed, ease);
                } else if (aimationDirection === "left") {
                    animationWidthLeft(element, x, y, startWidth, endWidth, speed, ease, reverse, isCoordinates);
                }
            }

            function animationWidthRight(element, width, speed, ease) {
                return element
                    .transition()
                    .duration(speed)
                    .ease(ease)
                    .attr("width", width + "px");
            }

            function animationPositionX(element, x, y, speed, ease) {
                return element
                    .transition()
                    .duration(speed)
                    .ease(ease)
                    .attr("transform", getTranslate(x, y));
            }

            function animationWidthLeft(element, x, y, startWidth, endWidth, speed, ease, reverse, isCoordinates) {
                var animation = animationWidthRight(element, (reverse ? startWidth : endWidth), speed, ease);

                var newX = x - (endWidth - startWidth);

                if (isCoordinates) {
                    animation.attr("x", reverse ? x + "px" : newX + "px");
                } else {
                    animation.attr("transform", getTranslate(reverse ? x : newX, y))
                }
            }

            function addBlurFilter(defs) {
                if (defs.select("#" + options.shadowFilterId)[0][0]) {
                    return;
                }

                defs.append("filter")
                    .attr("id", options.shadowFilterId)
                    .append("feGaussianBlur")
                    .attr("result", "blurOut")
                    .attr("in", "matrixOut")
                    .attr("stdDeviation", options.shadow.blur);
            }

            function createTip(container, fn) {
                if (!fn) {
                    return null;
                }

                var offsetXY = [-10, 0];

                var tip = d3.tip()
                    .attr("class", "d3-tip auto")
                    .offset(offsetXY)
                    .html(fn);

                container.call(tip);

                return tip;
            }
        };

        return result;
    }]);